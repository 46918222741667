enum DestinationPageTabTypes {
  artSpaces = 'art_spaces',
  details = 'details',
  insiderTips = 'insider_tips',
  insiderTipSummary = 'insider_tip_summary',
  mustSee = 'must_see',
  whatsHappening = 'whats_happening',
}

export default DestinationPageTabTypes;
