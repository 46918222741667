import ITab from '@/interfaces/ITab';
import DestinationPageTabTypes from './DestinationPageTabTypes.enum';

export const destinationPageTabs: ITab[] = [
  {
    id: DestinationPageTabTypes.details,
    title: 'About',
    display: true,
  },
  {
    id: DestinationPageTabTypes.mustSee,
    title: 'Must sees',
    display: true,
  },
  {
    id: DestinationPageTabTypes.whatsHappening,
    title: 'What’s happening',
    display: true,
  },
  {
    id: DestinationPageTabTypes.artSpaces,
    title: 'Museums & Galleries',
    display: true,
  },
  {
    id: DestinationPageTabTypes.insiderTips,
    title: 'Insider Tips',
    display: false,
  },
  {
    id: DestinationPageTabTypes.insiderTipSummary,
    title: 'While in Town',
    display: false,
  },
];
